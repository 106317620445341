/* eslint-disable no-void */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable func-names */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
import { MutationSummary } from 'mutation-summary';
import main from './main';
import {
  WEBHOOK_URL,
  setCookie,
  getCookie,
  pollSubscriberIfNotIdentified,
  API_GATEWAY_BASE_URL,
} from '../helpers/utility';
import { identifySubscriberFromCheckoutExtension } from '../helpers/checkoutExtension';
import {
  TWOTAP_ID_COOKIE_NAME,
  SUBSCRIBER_ID_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
  SUBSCRIBER_ID_LOCAL_STORAGE_KEY,
  SUBSCRIBER_TOKEN_LOCAL_STORAGE_KEY,
  ESSENTIAL_TRACKING_EVENTS,
} from '../helpers/constants';
import { configureSubscriptionEnvironment } from '../helpers/events';
import { canUserBeTracked } from '../helpers/customerPrivacy';
import {
  getThirdPartyIdentifiers,
  isIdentifiedByThirdPartyCookie,
} from './identifiers/thirdPartyIdentifiers';
import registerPopupSdk from './core/popups/main';
import registerOnsiteOptInSdk from './core/onsite-opt-in/main';
import { publishPostscriptReadyEvent } from './core/initialization/events';

const delay = function (milliseconds) {
  var currentTime = new Date().getTime();
  while (currentTime + milliseconds >= new Date().getTime()) {}
};

export const handleEventSubscribe = (
  data,
  event,
  shopId,
  myshopify_domain,
  delayExecution,
) => {
  if (data && data.phone_number && data.accepts_sms) {
    const xhr = new XMLHttpRequest();
    const url = `${API_GATEWAY_BASE_URL}/api/subscriber/opt_in`;
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.response);
        const { subscriber_id: subscriberId, token } = response;
        configureSubscriptionEnvironment({
          isIframe: false,
          subscriberId,
          token,
        });
        if (!subscriberId) return;
        const subscriberEventRequest = new XMLHttpRequest();

        const subscriberEventUrl = `${WEBHOOK_URL}/v1/subscribers`;

        subscriberEventRequest.open('POST', subscriberEventUrl, true);
        subscriberEventRequest.setRequestHeader(
          'Content-Type',
          'application/json',
        );
        data.location_url = window.location.href;
        const subscriberEventPayload = JSON.stringify({
          subscriber_id: subscriberId,
          event,
          data,
        });
        subscriberEventRequest.send(subscriberEventPayload);
      }
    };
    data.location_url = window.location.href;
    const data2 = JSON.stringify({
      ...data,
      source: data.source ? data.source : null,
      shop_id: data.shop_id ? data.shop_id : shopId,
      myshopify_domain: data.myshopify_domain
        ? data.myshopify_domain
        : myshopify_domain,
    });
    xhr.send(data2);

    // avoid issues when potentially navigating away from page before async actions
    if (delayExecution) delay(1000);
  }
};

const initializeSdk = (shopId) => {
  const psConfig = window.psConfig || {};
  let page_type = null;
  let myshopify_domain = null;
  let ps_subscriber_id = null;

  const identifyPage = function () {
    try {
      let pageType = null;
      if (
        window.chData &&
        window.chData.order &&
        window.chData.order.order_id
      ) {
        pageType = 'carthook_purchase';
      } else if (window.CHDataObject && window.CHDataObject.checkout_session) {
        pageType = 'carthook_checkout';
      } else if (window.BOLD && window.BOLD.order) {
        pageType = 'bold_purchase';
      } else if (
        window.location.href.toLowerCase().indexOf('/r/checkout') > -1
      ) {
        pageType = 'recharge_checkout';
      } else if (window.location.href.toLowerCase().indexOf('/r/pay') > -1) {
        pageType = 'recharge_pay';
      } else if (
        window.location.href.toLowerCase().indexOf('/r/purchase') > -1
      ) {
        pageType = 'recharge_purchase';
      } else if (window.OCUConfig) {
        pageType = 'zipify_purchase';
      } else if (
        window.Shopify &&
        window.Shopify.checkout &&
        window.Shopify.checkout.order_id
      ) {
        pageType = 'shopify_purchase';
      } else if (
        window.Shopify &&
        window.Shopify.Checkout &&
        !(window.Shopify.checkout && window.Shopify.checkout.order_id)
      ) {
        pageType = 'shopify_checkout';
      }
      return pageType;
    } catch (e) {}
  };

  const searchForSubscriberId = function () {
    try {
      const url = new URL(window.location.href);
      let psID =
        url.searchParams.get('s-id') ||
        identifySubscriberFromCheckoutExtension(
          SUBSCRIBER_ID_LOCAL_STORAGE_KEY,
        );
      if (psID) {
        setCookie(SUBSCRIBER_ID_COOKIE_NAME, psID, 3650);
      } else {
        psID = getCookie(SUBSCRIBER_ID_COOKIE_NAME);
      }
      psID = psID || null;
      return psID;
    } catch (e) {}
  };

  const searchForToken = () => {
    const token = identifySubscriberFromCheckoutExtension(
      SUBSCRIBER_TOKEN_LOCAL_STORAGE_KEY,
    );
    if (token) {
      setCookie(TOKEN_COOKIE_NAME, token, 3650);
    }
  };

  const getAnonymousSubscriberToken = function () {
    // Gets token associated with non-subscriber
    const token = getCookie('ps_aid');
    return token || null;
  };

  const getTwoTapSessionId = function () {
    // Gets token associated with a two-tap session
    const token = getCookie(TWOTAP_ID_COOKIE_NAME);
    return token || null;
  };

  const searchForMyShopifyDomain = function () {
    try {
      const url = new URL(window.location.href);
      let myShopifyDomain = url.searchParams.get('myshopify_domain');
      if (!myShopifyDomain && window.Shopify && window.Shopify.shop) {
        myShopifyDomain = window.Shopify.shop;
      }
      if (
        !myShopifyDomain &&
        window.Shopify &&
        window.Shopify.Checkout &&
        window.Shopify.Checkout.apiHost
      ) {
        myShopifyDomain = window.Shopify.Checkout.apiHost;
      }
      if (
        !myShopifyDomain &&
        window.CHDataObject &&
        window.CHDataObject.store_urls &&
        window.CHDataObject.store_urls.store_url
      ) {
        myShopifyDomain = window.CHDataObject.store_urls.store_url;
      }
      if (!myShopifyDomain && window.BOLD && window.BOLD.config) {
        myShopifyDomain = window.BOLD.config.shop_url;
      }
      myShopifyDomain = myShopifyDomain || null;
      return myShopifyDomain;
    } catch (e) {}
  };

  const registerPostscript = function () {
    window.Postscript = {
      isSubscriberInputChecked: false,
      async identify(search) {
        const result = {
          found: false,
          message: 'No matching subscriber found.',
        };
        if (!search) {
          result.message = 'Must provide search object with phone field.';
          return result;
        }
        if (!search.phone) {
          result.message = 'Search object must contain phone field.';
          return result;
        }
        const url = `${API_GATEWAY_BASE_URL}/v2/public/subscriber/identify/?shopId=${shopId}&phone=${search.phone}`;
        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            if (data.id) {
              setCookie(SUBSCRIBER_ID_COOKIE_NAME, data.id, 3650);
              result.found = true;
              result.message = 'Cookied subscriber via subscriber id.';
              return result;
            }
            if (data.token) {
              setCookie(TOKEN_COOKIE_NAME, data.token, 3650);
              result.found = true;
              result.message = 'Cookied subscriber via anonymous token.';
              return result;
            }
          }
          return result;
        } catch (err) {
          result.message = `Error fetching subscriber: ${err}`;
          return result;
        }
      },
      getSubscriberId() {
        return searchForSubscriberId();
      },
      async event(event, data, delayExecution = true) {
        try {
          if (!event) return;
          if (event === 'viewed_product') return;

          const canTrackUserData = await canUserBeTracked(window.Shopify);
          const anonymousSubscriberToken = getAnonymousSubscriberToken();
          const twoTapSessionId = getTwoTapSessionId();
          const thirdPartyIdentifiers = getThirdPartyIdentifiers();

          const canUserBeIdentified =
            ps_subscriber_id ||
            anonymousSubscriberToken ||
            twoTapSessionId ||
            isIdentifiedByThirdPartyCookie(thirdPartyIdentifiers);
          const isOptInEvent = data.accepts_sms;
          const isTrackingAllowed =
            canTrackUserData ||
            ESSENTIAL_TRACKING_EVENTS.includes(event) ||
            isOptInEvent;

          if (canUserBeIdentified && isTrackingAllowed) {
            const xhr = new XMLHttpRequest();

            const url = `${WEBHOOK_URL}/v1/subscribers`;

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            if (data) data.location_url = window.location.href;

            const requestPayload = JSON.stringify({
              subscriber_id: ps_subscriber_id || null,
              token: anonymousSubscriberToken || null,
              server_id: twoTapSessionId || null,
              ...thirdPartyIdentifiers,
              event,
              data: data || null,
            });
            xhr.send(requestPayload);
            return;
          }

          handleEventSubscribe(
            data,
            event,
            shopId,
            myshopify_domain,
            delayExecution,
          );
        } catch (e) {}
      },
    };
    registerPopupSdk(window.Postscript);
    registerOnsiteOptInSdk(window.Postscript, shopId);
    window.postscript = window.Postscript;
  };

  const init = function () {
    page_type = identifyPage();
    ps_subscriber_id = searchForSubscriberId();
    myshopify_domain = searchForMyShopifyDomain();
    if (!ps_subscriber_id) {
      searchForToken();
    }
  };

  const fireDOMLoadEvents = function () {
    try {
      if (!page_type) return;
      if (!ps_subscriber_id) {
        addSubscriberSection();
      }
    } catch (e) {}
  };

  const fireLoadEvents = function () {
    try {
      if (!page_type) return;
      let source = 'Shopify Checkout';
      if (page_type.includes('carthook')) {
        source = 'CartHook Checkout';
      } else if (page_type.includes('recharge')) {
        source = 'ReCharge Checkout';
      } else if (page_type.includes('bold')) {
        source = 'Bold Checkout';
      } else if (page_type.includes('zipify')) {
        source = 'Zipify Checkout';
      } else if (page_type.includes('shopify')) {
        source = 'Shopify Checkout';
      }
      const externalOrderId = window.ps__order_id ? window.ps__order_id : null;
      if (window.Postscript && page_type === 'shopify_purchase') {
        window.Postscript.event('purchase', {
          order_id: window.Shopify.checkout.order_id,
          page_type,
          source,
        });
      }
      if (window.Postscript && page_type === 'carthook_purchase') {
        window.Postscript.event('purchase', {
          order_id: window.chData.order.order_id,
          page_type,
          source,
        });
      }
      if (window.Postscript && page_type === 'carthook_checkout') {
        window.Postscript.event('checkout_started', { page_type, source });
      }
      if (window.Postscript && page_type === 'bold_purchase') {
        window.Postscript.event('purchase', {
          order_id:
            window.BOLD && window.BOLD.order
              ? window.BOLD.order.public_order_id
              : null,
          page_type,
          source,
        });
      }
      if (window.Postscript && page_type === 'zipify_purchase') {
        window.Postscript.event('purchase', {
          order_id: externalOrderId,
          page_type,
          source,
        });
      }
      if (window.Postscript && page_type === 'zipify_checkout') {
        window.Postscript.event('checkout_started', { page_type, source });
      }
      if (window.Postscript && page_type === 'recharge_purchase') {
        window.Postscript.event('purchase', {
          order_id: externalOrderId,
          page_type,
          source,
        });
      }
      if (window.Postscript && page_type === 'recharge_checkout') {
        window.Postscript.event('checkout_started', { page_type, source });
      }
      if (window.Postscript && page_type === 'recharge_pay') {
        window.Postscript.event('payment_started', { page_type, source });
      }
      if (document.querySelector('button[type="submit"]')) {
        document.querySelectorAll('button[type="submit"]').forEach((n) => {
          n.addEventListener(
            'click',
            () => {
              submitClicked();
            },
            false,
          );
        });
      }
      if (document.querySelector('input[type="submit"]')) {
        document.querySelectorAll('input[type="submit"]').forEach((n) => {
          n.addEventListener(
            'click',
            () => {
              submitClicked();
            },
            false,
          );
        });
      }
    } catch (e) {}
  };

  const getEmailElement = function () {
    let emailElement = document.getElementById('customer_email');
    if (!emailElement) emailElement = document.getElementById('checkout_email');
    return emailElement;
  };

  const getPhoneElement = function () {
    let phoneElement = document.getElementById('ps_phone_number');
    if (!phoneElement) phoneElement = document.getElementById('shipping_phone');
    if (!phoneElement)
      phoneElement = document.getElementById('checkout_shipping_address_phone');
    return phoneElement;
  };

  var submitClicked = function () {
    const acceptsElement = document.getElementById('ps_accepts_sms');
    const phoneElement = getPhoneElement();
    const emailElement = getEmailElement();
    let aVal = false;
    let pVal = null;
    let eVal = null;
    if (acceptsElement) {
      if (acceptsElement.dataset && acceptsElement.dataset.acceptsSms) {
        aVal = acceptsElement.dataset.acceptsSms === 'true';
      } else if (acceptsElement.checked) {
        aVal = acceptsElement.checked === true;
      }
    }
    if (phoneElement && phoneElement.value) pVal = phoneElement.value;
    if (emailElement && emailElement.value) eVal = emailElement.value;
    let source = 'Checkout';
    if (page_type.includes('carthook')) {
      source = 'CartHook Checkout';
    } else if (page_type.includes('recharge')) {
      source = 'ReCharge Checkout';
    } else if (page_type.includes('bold')) {
      source = 'Bold Checkout';
    } else if (page_type.includes('zipify')) {
      source = 'Zipify Checkout';
    } else if (page_type.includes('shopify')) {
      source = 'Shopify Checkout';
    }
    if (aVal && pVal) {
      window.Postscript.event('customer_submitted', {
        accepts_sms: aVal,
        phone_number: pVal,
        source,
        email: eVal,
      });
    }
  };

  var createShopifySubscriberSection = function (tries) {
    const ps_container = document.createElement('div');
    ps_container.id = 'ps__container_root';

    let element;
    if (psConfig.checkboxElement) {
      element = document.querySelector(psConfig.checkboxElement);
    } else if (document.querySelector("[data-address-field='phone']")) {
      element = document.querySelector("[data-address-field='phone']");
    } else {
      element = document.querySelector('#checkout_email')
        ? document.querySelector('#checkout_email').closest('div.section')
        : document.querySelector('#checkout_email_or_phone')
        ? document
            .querySelector('#checkout_email_or_phone')
            .closest('div.section')
        : document.querySelector('#checkout_phone')
        ? document.querySelector('#checkout_phone').closest('div.section')
        : null;
    }

    if (!element && (tries < 10 || !tries)) {
      setTimeout(() => {
        createShopifySubscriberSection(tries + 1);
      }, 500);
    }

    if (!document.getElementById('ps__container_root') && element)
      element.appendChild(ps_container);

    main({
      checkoutType: page_type,
      hostname: myshopify_domain,
      shouldRenderPhoneInput: !getPhoneElement(),
      psConfig,
    });
  };

  var createCHSubscriberSection = function (tries) {
    const ps_container = document.createElement('div');
    ps_container.id = 'ps__container_root';

    let element;
    if (psConfig.checkboxElement) {
      element = document.querySelector(psConfig.checkboxElement);
    } else if (document.querySelector('shipping-address-fieldset')) {
      element = document.querySelector('shipping-address-fieldset');
    } else {
      element = document.querySelector('#customer_email')
        ? document.querySelector('#customer_email').closest('div.row')
        : null;
    }

    if (!element && (tries < 10 || !tries)) {
      setTimeout(() => {
        createCHSubscriberSection(tries + 1);
      }, 500);
    }

    if (!document.getElementById('ps__container_root') && element)
      element.appendChild(ps_container);

    main({
      checkoutType: page_type,
      hostname: myshopify_domain,
      shouldRenderPhoneInput: !getPhoneElement(),
      psConfig,
    });
  };

  var createRCSubscriberSection = function (tries) {
    const ps_container = document.createElement('div');
    ps_container.id = 'ps__container_root';

    let element;
    if (psConfig.checkboxElement) {
      element = document.querySelector(psConfig.checkboxElement);
    } else if (document.querySelector('#shipping-address .field.phone')) {
      element = document.querySelector('#shipping-address');
    } else {
      element = document.querySelector('#checkout_email_section');
    }
    if (!element && (tries < 10 || !tries)) {
      setTimeout(() => {
        createRCSubscriberSection(tries + 1);
      }, 500);
    }

    if (!document.getElementById('ps__container_root') && element)
      element.parentNode.insertBefore(ps_container, element.nextSibling);

    main({
      checkoutType: 'recharge',
      hostname: myshopify_domain,
      shouldRenderPhoneInput: !getPhoneElement(),
      psConfig,
    });
  };

  var addSubscriberSection = function () {
    if (document.getElementById('ps_accepts_sms')) return;

    if (page_type.includes('carthook')) {
      createCHSubscriberSection();
    } else if (page_type.includes('recharge')) {
      createRCSubscriberSection();
    } else if (page_type.includes('zipify')) {
      createShopifySubscriberSection();
    } else if (page_type.includes('shopify')) {
      createShopifySubscriberSection();
    }
  };

  const initMutationObserver = () => {
    const observerCallback = ([summary]) => {
      if (!summary || !summary.removed || !summary.removed.length > 0) return;

      fireDOMLoadEvents();
    };

    // eslint-disable-next-line no-new
    new MutationSummary({
      callback: observerCallback,
      queries: [
        {
          element: '#ps__container_root',
        },
      ],
    });
  };

  let t = !1;

  var e = function () {
    if (document.readyState && !/loaded|complete/.test(document.readyState)) {
      return void setTimeout(e, 10);
    }

    if (!t) {
      // eslint-disable-next-line no-return-assign
      return (t = !0), void setTimeout(e, 50);
    }

    init();
    fireDOMLoadEvents();
    fireLoadEvents();
    initMutationObserver();
    window.dispatchEvent(new Event('ps-sdk-init'));
    publishPostscriptReadyEvent();
    pollSubscriberIfNotIdentified();
  };

  registerPostscript();
  e();
};

export default initializeSdk;
