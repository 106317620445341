import { SdkConfig } from '../sdk/subscriber-events/utils';
import { API_GATEWAY_BASE_URL } from './utility';

export const fetchConfig = async (
  shopId: string | null,
  shopDomain?: string,
): Promise<SdkConfig | null> => {
  const apiEndpoint = shopId
    ? `${API_GATEWAY_BASE_URL}/sdk/config?shop_id=${shopId}`
    : `${API_GATEWAY_BASE_URL}/sdk/config?shop_domain=${shopDomain}`;

  try {
    const response = await fetch(apiEndpoint);
    return response.ok ? response.json() : null;
  } catch {
    return null;
  }
};
