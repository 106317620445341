import { calculateElementSize } from './utility';
import { POPUP_CONTAINER_ID, POPUP_CLOSE_MESSAGE_TYPE } from './constants';
import { configStateStore } from '../widget/common/helpers/stateManager';

const LEFT_OFFSET = 10;
const BOTTOM_OFFSET = 10;

export const GET_PARENT_FOCUSED_ELEMENT_EVENT = 'GetFocusedElement';
export const STORED_PARENT_FOCUSED_ELEMENT_EVENT = 'FocusedElementStored';
export const RESTORE_PARENT_FOCUSED_ELEMENT_EVENT = 'RestoreFocusedElement';
export const HIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT =
  'HideElementsBehindPopupFromScreenReader';
export const UNHIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT =
  'UnhideElementsBehindPopupFromScreenReader';

interface IframePosition {
  top: string | number;
  left: string | number;
  bottom: string | number;
  right: string | number;
}

export const resetIframe = (
  height: string,
  width: string,
  positionObj: IframePosition,
  shouldStopScroll?: boolean,
) => {
  window.parent.postMessage(
    {
      FrameHeight: height,
      FrameWidth: width,
      FramePosition: positionObj,
      shouldStopScroll,
    },
    '*',
  );
};

const DEFAULT_OFFSET = {
  top: 'auto',
  left: `${LEFT_OFFSET}px`,
  bottom: `${BOTTOM_OFFSET}px`,
  right: 'auto',
};
export const resizeIframeForSoftClose = (
  bodyElementId: string,
  closeElementId: string,
  offset: {
    top: string;
    left: string;
    bottom: string;
    right: string;
  } = DEFAULT_OFFSET,
) => {
  const DEFAULT_WIDTH = 160;
  const DEFAULT_HEIGHT = 60;
  const { width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT } =
    calculateElementSize(bodyElementId);
  const { width: buttonWidth = 0, height: buttonHeight = 0 } =
    calculateElementSize(closeElementId);

  // height and width can sometimes be 0, in that case we fall back to the min height/width
  const effectiveHeight = height || DEFAULT_HEIGHT;
  const effectiveWidth = width || DEFAULT_WIDTH;
  const additionalWidth = LEFT_OFFSET + buttonWidth / 2 + 10;
  const additionalHeight = BOTTOM_OFFSET + buttonHeight / 2 + 10;

  resetIframe(
    `${effectiveHeight + additionalHeight}px`,
    `${effectiveWidth + additionalWidth}px`,
    offset,
  );
};

export const resizeIframeForFullScreen = (shouldStopScroll?: boolean) => {
  resetIframe(
    '100%',
    '100%',
    {
      top: 0,
      left: 0,
      bottom: 'auto',
      right: 'auto',
    },
    shouldStopScroll,
  );
};

export const showIframe = () => {
  window.parent.postMessage({ FrameDisplay: 'block' }, '*');
};

export const makeIframeVisible = () => {
  window.parent.postMessage({ FrameOpacity: '1' }, '*');
};

export const hideIframe = () => {
  window.parent.postMessage({ FrameDisplay: 'none', FrameOpacity: 0 }, '*');
};

export const requestFocusedElement = (
  storeFocusedElementIdCallback: (id: string | null) => void,
) => {
  const configManager = configStateStore.getState();
  if (configManager.isPreview) return;

  const isParentWindowFocused = document.activeElement?.tagName === 'BODY';
  if (!isParentWindowFocused) {
    storeFocusedElementIdCallback(document.activeElement?.id ?? null);
    return;
  }

  const handleParentMessage = (event: MessageEvent<string>) => {
    if (event.data === STORED_PARENT_FOCUSED_ELEMENT_EVENT) {
      storeFocusedElementIdCallback(null);
      event.target?.removeEventListener(
        'message',
        handleParentMessage as EventListenerOrEventListenerObject,
      );
    }
  };

  window.addEventListener('message', handleParentMessage);
  window.parent.postMessage(GET_PARENT_FOCUSED_ELEMENT_EVENT, '*');
};

export const restoreFocusedElement = (lastFocusedElementId: string) => {
  const lastFocusedElement = document.getElementById(lastFocusedElementId);
  if (lastFocusedElement) {
    lastFocusedElement.focus();
    return;
  }

  window.parent.postMessage(RESTORE_PARENT_FOCUSED_ELEMENT_EVENT, '*');
};

export const trapFocusInPopup = () => {
  const configManager = configStateStore.getState();
  if (configManager.isPreview) return;

  document.getElementById(POPUP_CONTAINER_ID)?.removeAttribute('aria-hidden');
  window.parent.postMessage(
    HIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT,
    '*',
  );
};

export const removePopupFocusTrap = () => {
  // When the popup is rejected it still exists in the DOM, so we must hide it from screen readers.
  document
    .getElementById(POPUP_CONTAINER_ID)
    ?.setAttribute('aria-hidden', 'true');
  window.parent.postMessage(
    UNHIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT,
    '*',
  );
};

export const setCookieOnParentDocument = (
  name: string,
  value: string | number,
  daysBeforeCookieExpires?: number,
  hoursBeforeCookieExpires?: number,
  allowSubdomains?: boolean,
) => {
  window.parent.postMessage(
    {
      SetCookie: {
        name,
        status: value,
        expirationDays: daysBeforeCookieExpires,
        expirationHours: hoursBeforeCookieExpires,
        allowSubdomains,
      },
    },
    '*',
  );
};

export const sendPopupCloseEvent = (
  popupId: number,
  popupName: string,
  hard: boolean,
) => {
  window.parent.postMessage(
    {
      type: POPUP_CLOSE_MESSAGE_TYPE,
      popupId,
      popupName,
      hard,
    },
    '*',
  );
};
